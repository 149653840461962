.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db; 
    border-radius: 50%;
    width: 50px; 
    height: 50px; 
    animation: spin 1s linear infinite; 
    margin-top: 25%;
    margin-left: 50%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.request-list-container {
    display: flex;
    width: 100%;
}

.arrow-icon-left-request-list {
    cursor: pointer;
    font-size: 24px;
    color: #333;
    transition: color 0.3s;
    margin-top: 1.5%;
    margin-left: -2%;

}

.arrow-icon-right-request-list {
    cursor: pointer;
    font-size: 24px;
    color: #333;
    transition: color 0.3s;
    margin-top: 1.5%;
    margin-right: 1.5%;
}

.arrow-icon-left-request-list:hover,
.arrow-icon-right-request-list:hover {
  color: darkblue;
}

.request-list {
    display: flex;
    gap: 16px;
    padding-bottom: 0.125%;
    margin-top: 1%;
    scroll-behavior: smooth;
    overflow: hidden;
    white-space: nowrap;
}

.request-box {   
    padding: 1%;
    border: 1px solid #EEEEEE;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    height: 2rem;
    width: 9rem;
    font-weight: bold;
    cursor: pointer;
}

.request-box.active {
    border: 1.5px solid darkblue;
}

.status-circle {
    border: 0.4rem solid;
    border-radius: 1rem;
    margin-left: 1.3rem;
}

/* Status Colors */
.status-circle.pending {
    border-color: #F6BE00;
}

.status-circle.accepted {
    border-color: #008000;
}

.status-circle.rejected {
    border-color: #FF2400;
}

.type-header {
    display: flex;
    align-items: center;
}

.type-header h3 {
    padding-right: 3rem;
}

.status-box {
    color: white;
    font-weight: bold;
    border-radius: 3px;
    width: 5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Status Box Colors */
.status-box.pending {
    background: #F6BE00;
}

.status-box.accepted {
    background: #008000;
}

.status-box.rejected {
    background: #FF2400;
}

.date-image-type-box {
    display: flex;
}

.date-box {
    margin-top: -3px;
    font-style: italic;
}

.image-type {
    padding-left: 50%;
    font-weight: bold;
    margin-top: -3px;
}

.content-container {
    display: flex; 
    height: 100%;
    gap: 4.75%;
}

/* Table Styling */
.table-container-retailer-edit {
    padding-right: 0.5%;
    width: 50%;
}

.table {
    border: solid 1px gray;
    border-collapse: collapse;
    width: 100%; 
}

.table-header {
    background: #EEEEEE;
    padding: 8px;
    border: 1px solid gray;
}

.table-cell {
    border: 1px solid gray;
    padding: 0.5rem;
    height: 1.5rem; 
    width: 10rem; 
    min-width: 10rem; 
    max-width: 11rem; 
}


.table-header {
    width: 10rem; 
    min-width: 10rem; 
    max-width: 11rem; 
}

.table-cell:hover {
    cursor: pointer;
}

.partition-line {
    width: 0.094rem;
    background-color: gray;
}

/* Image View */
.image-view {
    display: grid;
    flex: 1 0 auto;
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(2, 1fr); 
    gap: 0rem;
    height: 100%; 
    overflow-y: auto;
    max-height: 21.313rem;
    padding-left: 6%;
    width: 40%;
}

.image-item {
    width: 250px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s;
    transform: scale(1);
}

.image-item img {
    height: auto;       
    max-height: 100%;   
    object-fit: cover; 
    display: block;      
}

.icon-container {
    display: flex;            
    justify-content: center; 
    padding-top: 1rem; 
    padding-bottom: 2rem;
}

.reason-container {
    display: flex;
    align-items: center;
    padding-top: 3%;
}

.reason-text {
    font-weight: bold;
    padding-right: 1%;
}

.reason-input {
    border: 1px solid #EEEEEE;
    border-radius: 0.25rem;
    padding: 7.5px;
    width: 13.25rem;
    font-size: 11.75px;
    background-color: rgb(234, 234, 234);
    cursor: not-allowed;
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 16px; 
    color: white;
    padding-left: 50%;
}


.reject-button, .accept-button {
    width: 6rem; 
    height: 1.896rem; 
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.reject-button {
    background-color: #FF2400; 
}

.accept-button {
    background-color: #008000; 
}

.reject-button:hover {
    background-color: #EE4B2B; 
}

.accept-button:hover {
    background-color: #006400; 
}

.total-images-button:hover {
    background-color: #0000CC;
}

.popup-container {
    display: flex; 
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center; 
}

.popup-content {
    background-color: white;
    padding: 1%;
    border-radius: 5px; 
    width: 28.125rem; 
    height: 13rem;
    text-align: center;
}

.popup-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2%;
}

.popup-header {
    margin: 0;
}

.close-button {
    color: #aaa;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

.close-button:hover, .close-button:focus {
    color: black;
}

.popup-display-data {
    margin: 8% 0;
}

.popup-display-data span {
    font-weight: bold;
}

.confirm-button {
    width: 6rem; 
    height: 1.896rem; 
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #008000; 
    margin: 1rem auto 0; 
    margin-top: 5%;
}

.rejection-reason-dropdown {
    border: 1px solid #EEEEEE; 
    padding: 8px; 
    width: 100%; 
    cursor: pointer; 
    font-size: 0.75rem;
    margin-top: 5%;
}

.rejection-reason-dropdown-option {
    background-color: white; 
    color: gray;
}

.no-requests-message {
    font-size: 15px;
}